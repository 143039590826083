import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react"
import { ClerkApp, ClerkCatchBoundary } from "@clerk/remix"
import { rootAuthLoader } from "@clerk/remix/ssr.server"
import { HeadersFunction, LoaderArgs, MetaFunction } from "@remix-run/node"

import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  useCatch,
  useLoaderData,
  useLocation,
  useMatches,
} from "@remix-run/react"
import {
  dehydrate,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"

import { queryClient } from "~/utils/queryClient"
import { theme } from "~/utils/theme"
import { EnvProvider } from "./utils/hooks/useEnv"

import * as Sentry from "@sentry/remix"
import { withSentry } from "@sentry/remix"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import agGrid from "ag-grid-community/styles/ag-grid.css"
import agAlpine from "ag-grid-community/styles/ag-theme-alpine.css"
import agBalham from "ag-grid-community/styles/ag-theme-balham.css"
import agMaterial from "ag-grid-community/styles/ag-theme-material.css"
import agQuartz from "ag-grid-community/styles/ag-theme-quartz.css"
import { useEffect } from "react"
import { HiArrowLeft, HiExclamation } from "react-icons/hi"
import { IntercomProvider } from "react-use-intercom"
import { useDehydratedState } from "use-dehydrated-state"
import { ParsedEnv } from "~/types/global"
import { Card } from "./components"
import { getUserPermissions } from "./routes/__protected/api/user/permissions"
import { cacheKeys } from "./utils/cacheKeys"
import { clerkOptions } from "./utils/clerk"
import { getCompanyDeliveryMonth } from "./utils/db/companySignals"
import {
  getCompanyNames,
  getInvestorsNames,
  getLocationsNames,
  getSignalSourceNames,
} from "./utils/db/getAutocompleteNames"
import {
  get_environment,
  get_node_environment,
  intercomEnabled,
  zapierEnabled,
} from "./utils/env/common"
import { FeatureFlagsProvider } from "./utils/hooks/useFeatureFlags"
import { JSONSafeParse } from "./utils/JSONSafeParse"
import { make_timeago } from "./utils/timeago"
import { userIsTeamAdmin } from "./utils/userIsTeamAdmin.server"
import styles from "~/index.css"
import { getPageTitle } from "./components/Header"

make_timeago()

export const meta: MetaFunction = (params) => {
  const title = getPageTitle(params.location)
  if (!title) {
    return { title: "Specter" }
  }
  return { title: `Specter | ${title}` }
}

export function links() {
  return [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap",
    },
    { rel: "stylesheet", href: agGrid },
    { rel: "stylesheet", href: agAlpine },
    { rel: "stylesheet", href: agMaterial },
    { rel: "stylesheet", href: agQuartz },
    { rel: "stylesheet", href: agBalham },
    { rel: "stylesheet", href: styles },
  ]
}

function makeEnvObject(): ParsedEnv {
  return {
    APP_URL: process.env.APP_URL,
    DEFAULT_LOCALE: process.env.DEFAULT_LOCALE,
    NODE_ENV: get_node_environment(),
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_ENVIRONMENT: get_environment(),
    SENTRY_RELEASE: String(Date.now()),
    SITE_URL: process.env.SITE_URL,
    SUPABASE_PUBLIC: process.env.SUPABASE_PUBLIC,
    SUPABASE_URL: process.env.SUPABASE_URL,
    INTERCOM_ENABLED: intercomEnabled,
    INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
    ZAPIER_ENABLED: zapierEnabled,
    JUNE_WRITE_KEY: process.env.JUNE_WRITE_KEY,
    INTEGRATION_SERVICE_URL: process.env.INTEGRATION_SERVICE_URL,
  } as ParsedEnv
}

export const headers: HeadersFunction = () => ({
  "Cache-Control": "s-maxage=0",
})

export const loader = async (args: LoaderArgs) => {
  return rootAuthLoader(
    args,
    async ({ request }) => {
      const { userId } = request.auth
      const ssrQueryClient = new QueryClient()
      const env = makeEnvObject()
      const featureFlags = await prisma.featureFlags.findMany()

      if (userId) {
        const user = await getUserPermissions(userId)

        ssrQueryClient.setQueryData(cacheKeys.userPermissions, user)

        await ssrQueryClient.prefetchQuery({
          queryKey: cacheKeys.userIsTeamAdmin,
          queryFn: () => {
            return userIsTeamAdmin(userId)
          },
        })
      }

      await ssrQueryClient.prefetchQuery({
        queryKey: cacheKeys.companyDeliveryMonth,
        queryFn: () => {
          return getCompanyDeliveryMonth()
        },
      })

      const url = new URL(args.request.url)
      const query = JSONSafeParse<Record<string, any>>(
        url.searchParams.get("query") ?? "{}"
      )

      if ("Investors" in query) {
        await ssrQueryClient.prefetchQuery({
          queryKey: cacheKeys.inputOptions("investors"),
          queryFn: () => {
            return getInvestorsNames(query.Investors[1])
          },
          staleTime: Infinity,
        })
      }

      const COMPANY_AUTOCOMPLETE_FIELDS = [
        "Company",
        "acquirer",
        "acquired",
        "PastCompany",
        "NewCompany",
      ]

      if (COMPANY_AUTOCOMPLETE_FIELDS.some((key) => key in query)) {
        const ids = [
          ...new Set([
            ...COMPANY_AUTOCOMPLETE_FIELDS.map(
              (key) => query[key] ?? []
            ).flat(),
          ]),
        ]

        await ssrQueryClient.prefetchQuery({
          queryKey: cacheKeys.inputOptions("company"),
          queryFn: () => {
            return getCompanyNames(ids)
          },
          staleTime: Infinity,
        })
      }

      if ("SignalSource" in query) {
        await ssrQueryClient.prefetchQuery({
          queryKey: cacheKeys.inputOptions("source"),
          queryFn: () => {
            return getSignalSourceNames(query.SignalSource[1])
          },
          staleTime: Infinity,
        })
      }

      if ("Location" in query || "HQLocation" in query) {
        const locationRanges = [
          ...(query.Location ?? []),
          ...(query.HQLocation ?? []),
        ]

        await ssrQueryClient.prefetchQuery({
          queryKey: cacheKeys.inputOptions("location"),
          queryFn: () => {
            return getLocationsNames(locationRanges)
          },
        })
      }

      return {
        env,
        featureFlags,
        dehydratedState: dehydrate(ssrQueryClient),
      }
    },
    { loadUser: true }
  )
}

function App() {
  const loaderData = useLoaderData<typeof loader>()
  const { env, featureFlags } = loaderData ?? {}

  const dehydratedState = useDehydratedState()

  useEffect(() => {
    try {
      if (env?.SENTRY_DSN) {
        console.log("Sentry being initialised")
        Sentry.init({
          dsn: env?.SENTRY_DSN,
          environment: env?.SENTRY_ENVIRONMENT.toString(),
          release: env?.SENTRY_RELEASE,
          tracesSampleRate: 0.3,
          // This sets the sample rate to be 10%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.05,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 0.5,
          integrations: [
            new Sentry.Replay(),
            new Sentry.BrowserTracing({
              routingInstrumentation: Sentry.remixRouterInstrumentation(
                useEffect,
                useLocation,
                useMatches
              ),
            }),
          ],
        })
      }
    } catch (e) {
      console.warn("Unable to bring up Sentry", { env })
    }
  }, [env])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <Hydrate state={dehydratedState}>
            <EnvProvider env={env}>
              <FeatureFlagsProvider flags={featureFlags}>
                <IntercomProvider appId={env?.INTERCOM_APP_ID}>
                  <ChakraProvider theme={theme}>
                    <Outlet />
                  </ChakraProvider>
                </IntercomProvider>
              </FeatureFlagsProvider>
            </EnvProvider>
          </Hydrate>
        </QueryClientProvider>
        <Scripts />
        <LiveReload />
      </body>
    </html>
  )
}

const ErrorBoundaryUI = ({
  status,
  text,
}: {
  status?: number | string
  text?: string
}) => {
  const message = [status, text].filter(Boolean).join(" — ")

  return (
    <html lang="en">
      <head>
        <title>Specter - Error</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <Center
              minH="100dvh"
              backgroundColor="gray.50"
              backgroundImage="url(/grid-bg.svg)"
              backgroundPosition="top center"
              backgroundRepeat="repeat"
              backgroundSize="40px auto"
            >
              <Box
                p={1}
                borderWidth={1}
                rounded="xl"
                bgColor="white"
                shadow="0 4px 12px rgba(0, 0, 0, 0.1)"
              >
                <Card textAlign="center" p={10} rounded="lg">
                  <Icon as={HiExclamation} fontSize="4xl" />
                  <Heading size="md">We've encountered an error.</Heading>

                  {message && (
                    <Text color="gray.500" fontSize="sm">
                      {message}
                    </Text>
                  )}
                  <Button
                    as="a"
                    href="/"
                    colorScheme="brand"
                    mt={4}
                    leftIcon={<HiArrowLeft />}
                  >
                    Return to Home
                  </Button>
                </Card>
              </Box>
            </Center>
          </ChakraProvider>
        </QueryClientProvider>

        <Scripts />
      </body>
    </html>
  )
}

// see https://remix.run/docs/en/main/guides/not-found#md-root-catch-boundary
function SpecterCatchBoundary() {
  const caught = useCatch()
  return <ErrorBoundaryUI status={caught.status} text={caught.statusText} />
}

export function ErrorBoundary({ error }: { error: Error }) {
  console.error(error)
  Sentry.captureException(error)
  return <ErrorBoundaryUI />
}

export const CatchBoundary = ClerkCatchBoundary(SpecterCatchBoundary)

export default withSentry(ClerkApp(App, clerkOptions))
